import { SELLERS_URL, API_KEY_VALIDATE_DOCUMENT_NUMBER } from '../../config';
import getAxios from '../../clients';
import { AxiosResponse } from 'axios';
import { ISellerConfiguration } from './interfaces';

export const getSellerByDocumentNumber = async (documentSeller: string) =>
  getAxios
    .instance(API_KEY_VALIDATE_DOCUMENT_NUMBER)
    .get(`${SELLERS_URL}/v1/sellers/document-number/${documentSeller}`);

export const getSellerByName = async (name: string): Promise<AxiosResponse<any, any> | boolean> => {
  try {
    return await getAxios.instance(API_KEY_VALIDATE_DOCUMENT_NUMBER).get(`${SELLERS_URL}/v1/sellers/by-name/${name}`);
  } catch (ex) {
    return false;
  }
};

export const getSellerByEmail = async (adminEmail: string) =>
  getAxios.instance(API_KEY_VALIDATE_DOCUMENT_NUMBER).post(`${SELLERS_URL}/v1/sellers/findByAdminEmail/${adminEmail}`);

export const fetchIsInPublishHistory = async (accessToken: string, sellerId: string) => {
  try {
    const sellerHistory = await getAxios
      .instance(accessToken)
      .get(`${SELLERS_URL}/seller-publish-history/get-movements/${sellerId}`);
    return sellerHistory.data;
  } catch (ex) {
    return false;
  }
};

export const getSellerConfiguration = async (jwt: string, sellerId: string): Promise<ISellerConfiguration> => {
  try {
    const response = await getAxios.instance(jwt).get(`${SELLERS_URL}/sellers/seller-configuration/${sellerId}`);
    return response.data;
  } catch (e) {
    return {
      tourGuideStatus: 0,
      updateMailingData: 0,
      topsortLandingStatus: 0,
    };
  }
};

export const getRegionsByGiv = async () => {
  try {
    const response = await getAxios.instance().get(`${SELLERS_URL}/node-giv/regions`);
    return response.data.geographies
      .map((item: { code: string; name: string }) => ({
        value: item.code,
        label: item.name,
        children: item.name,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));
  } catch (error) {
    return [];
  }
};

export const getCommunesByRegionByGiv = async (region: string) => {
  try {
    const response = await getAxios.instance().get(`${SELLERS_URL}/node-giv/${region}/commune`);
    const [data] = response.data.geographies;
    return data.childGeographies
      .map((item: { name: string; code: string }) => ({
        label: item.name,
        value: item.code,
        children: item.name,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));
  } catch (error) {
    return [];
  }
};
