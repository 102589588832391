import { isAfter, isEqual } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import t from '../../utils/translate';
import { TermsModalInterface } from './interfaces';
import { Container } from './styles';
import { approveTerms } from '../../api/terms';
import SessionContext from '../../api/session/context';
import Html from '../Html';
import { CONTENT_URL_2, SPA_FLASH_MESSAGE_EVENT } from '../../config';
import { fixTimeZone, forceMidnight } from '../../utils/formatDates';
import { Button, Modal, Typography } from '@cencosud-ds/bigbang';

const TermsModal: React.FC<TermsModalInterface> = ({ terms, closeModal, seller }) => {
  const {
    auth: { jwt },
  } = useContext(SessionContext);

  const [position, setPosition] = useState<number>(0);
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(localStorage.getItem('_is_modal_review_later') === '0');
  const [buttonExitShow, setButtonExitShow] = useState<boolean>(false);
  const [finishValidationsSeller, setFinishValidationsSeller] = useState<boolean>(false);

  const today: Date = forceMidnight(new Date());

  useEffect(() => {
    if (
      seller &&
      seller.id !== '' &&
      (seller.vendor_code_801_client || seller.vendor_code_801_client !== '0') &&
      (seller.vendor_code_801_provider || seller.vendor_code_801_provider !== '0')
    ) {
      if (seller.publish === true && seller.terms_conditions_signed === 0) {
        setButtonExitShow(terms[position].exception === 0);
      } else {
        setButtonExitShow(terms[position].exception === 0);
      }
    } else {
      setButtonExitShow(terms[position].exception === 0);
    }
  }, [seller, position, terms]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinishValidationsSeller(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, [buttonExitShow]);

  useEffect(() => {
    terms.forEach(({ startDate }) => {
      const start = fixTimeZone(startDate);
      if (isAfter(today, start) || isEqual(start, today)) {
        setShouldShowModal(true);
      }
    });
    return () => {
      setPosition(0);
    };
  }, [terms, today]);

  const logOut = useCallback(() => {
    if (buttonExitShow) {
      closeModal();
    } else {
      localStorage.setItem('_is_modal_review_later', '1');
    }
    setOpenModal(false);
  }, [buttonExitShow]);

  const sendApproval = async () => {
    const response = await approveTerms(jwt, terms[position].id);

    if (!response) {
      const event = new Event(SPA_FLASH_MESSAGE_EVENT);
      // @ts-ignore
      event.data = {
        message: t('approveTermsError'),
        flashMessageType: 'danger',
      };
      window.dispatchEvent(event);
    } else {
      setOpenModal(false);
    }
    window.location.reload();
  };

  if (terms.length && shouldShowModal && finishValidationsSeller) {
    return (
      <Modal persistent={true} open={openModal} id="modal-terms" onClose={logOut}>
        <Container className="container-modal">
          <div className="description-modal">
            <Typography color="#166DC2" typeElement="h4" weight="semiBold">
              {t('titleModal')}
            </Typography>
            <div className="description-complement">
              <Typography color="#020B13" typeElement="body2" weight="regular">
                {t('tcWarning')}
              </Typography>
            </div>
          </div>
          {terms[position].content.includes('pdf') ? (
            <iframe
              title="termsfile"
              src={`${CONTENT_URL_2}/document/termsfile/${terms[position].content}`}
              width={'100%'}
              height={'100%'}
              frameBorder={0}
              className="content-pdf"
            />
          ) : (
            <div className="content">
              <Html>{terms[position].content.replace(/\\r|\\n/g, '')}</Html>
            </div>
          )}
          {/* <div>
            <Notification className="notification" extended={true} variant="warning">
              <p
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  letterSpacing: '0.25px',
                  lineHeight: '16px',
                }}
              >
                {t('warningModalOne')}
                <strong>
                  {`${moment(terms[position].endDate, 'YYYY-MM-DD').format('DD')} de`}&nbsp;
                  {`${moment(terms[position].endDate, 'YYYY-MM-DD').format('MMMM')}.`}&nbsp;
                </strong>
                {t('warningModalTwo')}
              </p>
            </Notification>
          </div> */}
          <div className="buttons">
            {buttonExitShow ? (
              <Button className="button-exit" onClick={logOut} type-button="secondary" variant-button="s">
                {t('log_out')}
              </Button>
            ) : (
              <Button className="button-exit" onClick={logOut} type-button="secondary" variant-button="s">
                {t('review_later')}
              </Button>
            )}
            <Button className="button-accept" onClick={sendApproval} type-button="primary" variant-button="s">
              {t('accept_legal_guidelines')}
            </Button>
          </div>
        </Container>
      </Modal>
    );
  }
  return null;
};

export default TermsModal;
