import * as Yup from 'yup';
import t from '../../../../../utils/translate';

export default () =>
  Yup.object({
    addressWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    regionWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    communeWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    nameContact: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    nameNode: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    lastnameContact: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    emailWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field'))
      .email(t('invalid_email')),
    emailConfirmWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field'))
      .email(t('invalid_email'))
      .oneOf([Yup.ref('emailWarehouse'), null], t('email_doesnt_match')),
    rolWarehouse: Yup.string()
      .trim()
      .required(t('sign_up_logistic_required_field')),
    phoneWarehouse: Yup.string()
      .max(9, t('invalid_phone'))
      .required(t('sign_up_logistic_required_field'))
      .matches(/^((2|9)[0-9]{8})/, t('invalid_phone')),
    technicalService: Yup.boolean().required(t('sign_up_logistic_required_field')),
  });
