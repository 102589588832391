/* eslint-disable */
import withStyles from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer/Footer';
import Header from 'components/Head';
import Snackbar from 'components/Snackbar/Snackbar';
import { Typography } from '@cencosud-ds/bigbang';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import PropTypes from 'prop-types';
import React from 'react';
import { getRoutes, homeRoute } from 'utils/routes';
import { withRouter } from 'react-router';
import t from 'utils/translate';
import { colors } from '@cencosud-ds/bigbang';
import {
  SPA_FLASH_MESSAGE_EVENT,
  SINGLE_SPA_ROUTE_EVENT,
  LOAD_SPA_MESSAGE_EVENT,
  SPA_ROUTES_READY,
  SPA_SERVICES_STATUS_READY,
} from '../../config';
import { SELLER } from 'variables/roles';
import SessionContext from 'api/session/context';
import { ContainerApp, Container, LoaderStyled, ContainerBreandcrum, ContainerBreadcrumbsAccesHall } from './styles';

import ModalConference from 'components/ModalConference';
import objectHasProperty from 'utils/objectHasProperty.ts';
import Eiffel from '../pages/eiffel';
import { LearningGuide } from '../pages/learningGuide';
import { ServiceUnavailableCurtain } from '../pages/serviceUnavailableCurtain';

import RouteBreadcrumb from 'components/RouteBreadcrumb';
import TermsModal from 'components/TermsModal';

class App extends React.Component {
  static contextType = SessionContext;

  state = {
    mobileOpen: false,
    miniActive: true,
    routes: [],
    permissions: {},
    servicesStatus: null,
    loadingMicroFront: true,
    showTerms: false,
  };

  constructor(props) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.storeRoutes = this.storeRoutes.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  handleMessage(event) {
    const {
      data: { message, flashMessageType },
    } = event;
    const { flashMessage, flashMessageTimeout } = this.context;
    if (message !== flashMessage.message) {
      flashMessageTimeout({ message, type: flashMessageType });
    }
  }

  storeRoutes(props) {
    const { routes, permissions, servicesStatus, forceUpdateServicesStatus } = props;
    if (this.state.loadingMicroFront || forceUpdateServicesStatus) {
      this.setState(prevState => ({
        routes: !routes ? prevState.routes : routes,
        permissions: !permissions ? prevState.permissions : permissions,
        servicesStatus: !servicesStatus ? prevState.servicesStatus : servicesStatus,
        loadingMicroFront: false,
      }));
    }
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1 && objectHasProperty(this.refs, 'mainPanel')) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener('resize', this.resizeFunction);
    window.addEventListener(SPA_FLASH_MESSAGE_EVENT, this.handleMessage);

    const event = new Event(LOAD_SPA_MESSAGE_EVENT);
    window.dispatchEvent(event);

    if (this.props.location.pathname === '/') {
      const event = new Event(SINGLE_SPA_ROUTE_EVENT);
      event.data = { url: homeRoute.path };
      window.dispatchEvent(event);
      this.props.history.push(homeRoute.path);
    }

    window.addEventListener(SPA_ROUTES_READY, this.storeRoutes);
    window.addEventListener(SPA_SERVICES_STATUS_READY, this.storeRoutes);
  }

  componentDidUpdate(e) {
    const { terms } = this.context;

    if (terms.length && !this.state.showTerms) {
      this.setState({ ...this.state, showTerms: true });
    }

    if (objectHasProperty(this.refs, 'mainPanel') && e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
    window.removeEventListener(SPA_FLASH_MESSAGE_EVENT, this.handleMessage);
    window.removeEventListener(SPA_ROUTES_READY, this.storeRoutes);
    window.removeEventListener(SPA_SERVICES_STATUS_READY, this.storeRoutes);
  }

  closeModal() {
    this.context.logout();
    window.location.reload();
    this.setState({ ...this.state, showTerms: !this.state.showTerms });
  }

  render() {
    const { classes, ...rest } = this.props;
    const {
      flashMessage: { message, type },
      auth: { role, sellerType, isCollector, sellerIsInPublishHistory },
      isModalInfo,
    } = this.context;
    const appStyle = this.state.loadingMicroFront ? { display: 'none' } : {};
    const loadingStyle = !this.state.loadingMicroFront ? { display: 'none' } : {};

    const parsedRoutes = getRoutes(this.state.routes, role, this.state.permissions, sellerType, isCollector);

    const currentService = this.state.servicesStatus && this.state.servicesStatus.getService(location.pathname);
    const isCurrentServiceUnavailable =
      this.state.servicesStatus && this.state.servicesStatus.getIsServiceUnavailable(location.pathname);

    if (this.state.servicesStatus && this.state.servicesStatus.hasAllServicesUnavailable) {
      this.props.history.push('/logout');
      return null;
    }

    return (
      <>
        <div style={loadingStyle}>
          <LoaderStyled size="large" />
        </div>
        <div {...this.props} style={appStyle}>
          <Header />
          <Container>
            {[SELLER].includes(role) && <LearningGuide />}
            <Navigation routes={parsedRoutes.filter(({ hidden }) => !hidden)} />
            <div
              className="container-breandcrum"
              style={{
                backgroundColor:
                  ['campaigns', 'stock', 'eiffel', 'reports'].includes(this.props.location.pathname.split('/')[1]) ||
                  ['multiwinery'].includes(this.props.location.pathname.split('/')[2])
                    ? colors.primary[100]
                    : colors.feedback.information.light,
              }}
            >
              {(sellerIsInPublishHistory ||
                ![SELLER].includes(role) ||
                this.props.location.pathname.split('/')[1] !== 'eiffel') && (
                <ContainerBreandcrum>
                  <RouteBreadcrumb routes={parsedRoutes} />
                </ContainerBreandcrum>
              )}
              {!sellerIsInPublishHistory && this.props.location.pathname.split('/')[1] === '/eiffel' && (
                <ContainerBreadcrumbsAccesHall>
                  <Typography color="#000000" propsElement={{}} typeElement="body3" weight="regular">
                    {`${t('you_are_in')}: ${t('access_hall')} `}
                  </Typography>
                </ContainerBreadcrumbsAccesHall>
              )}
            </div>

            <Eiffel />

            <ContainerApp
              id="root"
              withMargin={this.state.routes.length > 0}
              isLearningGuide={[SELLER].includes(role)}
              isInvoice={['campaigns', 'invoice', 'reports'].includes(this.props.location.pathname.split('/')[1])}
              isMyAccount={this.props.location.pathname.split('/')[2] === 'myAccount'}
              style={{
                backgroundColor:
                  ['campaigns', 'invoice', 'stock', 'eiffel', 'reports'].includes(
                    this.props.location.pathname.split('/')[1],
                  ) || ['multiwinery', 'myAccount'].includes(this.props.location.pathname.split('/')[2])
                    ? colors.primary[100]
                    : colors.feedback.information.light,
              }}
            />

            <ServiceUnavailableCurtain
              type="current"
              currentService={currentService}
              isCurrentServiceUnavailable={isCurrentServiceUnavailable}
            />
            {message && <Snackbar place="bc" color={type || 'info'} message={t(message)} open={message !== null} />}
          </Container>
          <Footer />
        </div>
        {isModalInfo && <ModalConference />}
        {this.state.showTerms && (
          <TermsModal
            showModal={this.state.showTerms}
            terms={this.context.terms}
            closeModal={this.closeModal}
            seller={this.context.seller}
          />
        )}
      </>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(dashboardStyle)(App));
